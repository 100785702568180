define('ember-json-schema-views/components/schema-field-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var schedule = Ember.run.schedule;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var DEFAULT_SIZE = exports.DEFAULT_SIZE = 'small';
  var DEFAULT_SHOW_LABELS = exports.DEFAULT_SHOW_LABELS = false;
  var DEFAULT_TRUE_LABEL = exports.DEFAULT_TRUE_LABEL = 'True';
  var DEFAULT_FALSE_LABEL = exports.DEFAULT_FALSE_LABEL = 'False';

  exports.default = Component.extend({
    classNames: ['schema-field-component', 'schema-field-toggle'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var key = this.get('key');
      var document = this.get('document');

      schedule('afterRender', function () {
        var defaultValue = _this.get('property.default');
        var initialValue = false;
        var documentValue = document.get(key);
        if (typeof defaultValue !== 'undefined') {
          initialValue = defaultValue;
        }

        if (typeof documentValue !== 'undefined') {
          initialValue = documentValue;
        }

        _this.set('value', initialValue);

        if (initialValue !== documentValue) {
          document.set(key, initialValue);
        }
      });
    },


    toggleSize: computed('property.displayProperties.toggleSize', function () {
      return this.get('property.displayProperties.toggleSize') || DEFAULT_SIZE;
    }),

    showLabels: computed('property.displayProperties.showLabels', function () {
      return this.get('property.displayProperties.showLabels') || DEFAULT_SHOW_LABELS;
    }),

    trueLabel: computed('property.displayProperties.labels.trueLabel', function () {
      return (this.get('property.displayProperties.labels.trueLabel') || DEFAULT_TRUE_LABEL) + '::true';
    }),

    falseLabel: computed('property.displayProperties.labels.falseLabel', function () {
      return (this.get('property.displayProperties.labels.falseLabel') || DEFAULT_FALSE_LABEL) + '::false';
    }),

    name: alias('key'),

    actions: {
      onToggle: function onToggle(newValue) {
        var document = this.get('document');
        document.set(this.get('key'), newValue);
        this.set('value', newValue);
        this.sendAction('changed', newValue); // eslint-disable-line ember/closure-actions
      }
    }
  });
});