define('ember-json-schema-document/utils/build-default-value-for-type', ['exports', 'ember-json-schema-document/models/document-object'], function (exports, _documentObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildDefaultValueForType;
  function buildDefaultValueForType(type) {
    switch (type) {
      case 'object':
        return _documentObject.default.create({});
      case 'array':
        return Ember.A();
      default:
        return undefined;
    }
  }
});