define('ember-json-schema-views/components/schema-field-select/component', ['exports', 'ember-json-schema-views/mixins/components/schema-field-initializer'], function (exports, _schemaFieldInitializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var getWithDefault = Ember.getWithDefault;
  var isNone = Ember.isNone;
  exports.default = Component.extend(_schemaFieldInitializer.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var _getProperties = this.getProperties(['key', 'property', 'document']),
          key = _getProperties.key,
          property = _getProperties.property,
          document = _getProperties.document;

      if (!isNone(document.get(key))) {
        // Document has a value set, don't overwrite what is set
        return;
      }

      var initialValue = void 0;

      if (property.default) {
        // Property has a default value
        initialValue = property.default;
      } else if (!getWithDefault(property, 'displayProperties.prompt', false)) {
        // No Prompt
        initialValue = property.validValues[0];
      }

      document.set(key, initialValue);
      this.set('value', initialValue);
    },


    classNames: ['schema-field-component', 'schema-field-select'],
    getCurrentValue: function getCurrentValue() {
      return this.$('select').val();
    }
  });
});