define('ember-json-schema-document/utils/get-properties', ['exports', 'ember-json-schema-document/models/schema', 'ember-json-schema-document/models/property'], function (exports, _schema, _property) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (object, rawProperties) {
    if (!(object instanceof _schema.default) && !(object instanceof _property.default)) {
      throw new Error('You must provide a schema or a property object to get the properties for.');
    }

    if (!object._properties) {
      var properties = object._properties = {};
      var keys = Object.keys(rawProperties);

      for (var i = 0, l = keys.length; i < l; i++) {
        var key = keys[i];
        var rawProperty = rawProperties[key];

        while (rawProperty.$ref) {
          rawProperty = object.resolveURI(rawProperty.$ref);
        }

        properties[key] = new _property.default(rawProperty, object, key, object.schemaStack);
      }
    }

    return object._properties;
  };
});