define('ember-json-schema-views/components/schema-field-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var schedule = Ember.run.schedule;
  exports.default = Component.extend({
    classNames: ['schema-field-component', 'schema-field-checkbox'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var key = this.get('key');
      var jsonDocument = this.get('document');
      var defaultValue = this.get('property.default');

      schedule('afterRender', function () {
        var initialValue = null;
        var documentValue = jsonDocument.get(key);

        if (typeof defaultValue !== 'undefined') {
          initialValue = defaultValue;
        }

        if (typeof documentValue !== 'undefined') {
          initialValue = documentValue;
        }

        if (initialValue !== null) {
          _this.set('value', initialValue);
          jsonDocument.set(key, initialValue);
        }
      });
    },


    actions: {
      update: function update(values) {
        var _getProperties = this.getProperties('document', 'key'),
            document = _getProperties.document,
            key = _getProperties.key;

        document.set(key, values);
        this.set('value', values);
        this.sendAction('changed', values); // eslint-disable-line ember/closure-actions
      }
    }
  });
});