define('ember-json-schema-views/components/property-options/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var schedule = Ember.run.schedule;
  var isNone = Ember.isNone;
  exports.default = Component.extend({
    tagName: '',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var property = this.get('property.property');
      var document = this.get('document');
      var defaultProps = { propertyOptions: { showProperty: true } };

      if (!property.isDependentProperty) {
        this.setProperties(defaultProps);
        return;
      }

      this.setProperties({
        isDependentProperty: true,
        dependsOnProperties: property.dependsOnProperties,
        propertyOptions: { showProperty: this._shouldPropertyBeVisible() }
      });

      property.dependsOnProperties.forEach(function (dependsOn) {
        var prop = dependsOn.property.documentPath;
        document.values.addObserver(prop, _this._updateProperties.bind(_this));
      });

      document.values.addObserver('didLoad', this._updateProperties.bind(this));
      this._updateProperties();
    },
    _shouldPropertyBeVisible: function _shouldPropertyBeVisible() {
      var property = this.get('property.property');
      var document = this.get('document');
      var dependencyCount = property.dependsOnProperties.length;

      var validDependencies = property.dependsOnProperties.filter(function (dependsOn) {
        // `dependsOn.values` is an array of required values.  If the property's
        // current value is included in this array, it is now required
        var currentValue = document.get(dependsOn.property.documentPath);

        if (dependsOn.property.type === 'array') {
          // For array types, we are checking to see if any of the current values
          // are included in `dependsOn.values`.
          return Array.isArray(currentValue) && currentValue.filter(function (value) {
            return dependsOn.values.indexOf(value) > -1;
          }).length > 0;
        } else {
          return dependsOn.values.indexOf(currentValue) > -1;
        }
      }).length;

      return validDependencies === dependencyCount;
    },
    _updateProperties: function _updateProperties() {
      var _this2 = this;

      var property = this.get('property.property');
      var document = this.get('document');
      var showProperty = this._shouldPropertyBeVisible();
      if (showProperty !== this.get('propertyOptions.showProperty')) {
        this.set('propertyOptions.showProperty', showProperty);
      }

      schedule('afterRender', function () {
        if (showProperty) {
          _this2._setVisibleValue();
        } else if (document.get(property.documentPath) !== null) {
          document.set(property.documentPath, null);
        }
      });
    },
    _setVisibleValue: function _setVisibleValue() {
      var document = this.get('document');
      var property = this.get('property.property');
      var defaultValue = this.get('property.default');
      var value = void 0;

      if (!isNone(document.get(property.documentPath))) {
        return;
      }

      if (isNone(defaultValue)) {
        value = { 'array': [], 'string': '', 'object': {}, 'boolean': false }[property.type];
      } else {
        value = defaultValue;
      }

      if (!isNone(value)) {
        document.set(property.documentPath, value);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var property = this.get('property.property');
      var document = this.get('document');

      if (!property.isDependentProperty) {
        return;
      }

      property.dependsOnProperties.forEach(function (dependsOn) {
        document.values.removeObserver(dependsOn.property.documentPath);
      });

      document.values.removeObserver('didLoad', this._updatedPropertyListener);
    }
  });
});