define('ember-json-schema-views/components/recommended-label/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  var Component = Ember.Component;
  var DEFAULT_LABEL = exports.DEFAULT_LABEL = '(recommended)';
  exports.default = Component.extend({
    tagName: '',

    recommendedValue: alias('property.displayProperties.recommendedValue'),
    recommendedLabel: computed('property.displayProperties.recommendedLabel', function () {
      return this.get('property.displayProperties.recommendedLabel') || DEFAULT_LABEL;
    }),

    isRecommended: computed('value', 'recommendedValue', function () {
      var _getProperties = this.getProperties('value', 'recommendedValue'),
          value = _getProperties.value,
          recommendedValue = _getProperties.recommendedValue;

      if (Array.isArray(recommendedValue)) {
        return recommendedValue.indexOf(value) > -1;
      }
      return recommendedValue === value;
    })
  });
});