define('ember-cli-toggle/components/x-toggle/component', ['exports', 'ember', 'ember-cli-toggle/components/x-toggle/template'], function (exports, _ember, _emberCliToggleComponentsXToggleTemplate) {
  var computed = _ember['default'].computed;
  var typeOf = _ember['default'].typeOf;
  // jshint ignore:line

  var a = _ember['default'].A;

  var xToggle = _ember['default'].Component.extend({
    layout: _emberCliToggleComponentsXToggleTemplate['default'],
    classNames: ['x-toggle-component'],

    name: 'default',
    disabled: false,
    value: 'off',
    onLabel: undefined,
    offLabel: undefined,

    _on: computed('onLabel', function () {
      var _getProperties = this.getProperties('onLabel', 'defaultOnLabel');

      var onLabel = _getProperties.onLabel;
      var defaultOnLabel = _getProperties.defaultOnLabel;

      return typeOf(onLabel) === 'undefined' ? defaultOnLabel : onLabel;
    }),

    _off: computed('offLabel', function () {
      var _getProperties2 = this.getProperties('offLabel', 'defaultOffLabel');

      var offLabel = _getProperties2.offLabel;
      var defaultOffLabel = _getProperties2.defaultOffLabel;

      return typeOf(offLabel) === 'undefined' ? defaultOffLabel : offLabel;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      // if value is not set to a valid state suggest a default to the container

      var _getProperties3 = this.getProperties('toggled', '_onValue', '_offValue');

      var toggled = _getProperties3.toggled;
      var _onValue = _getProperties3._onValue;
      var _offValue = _getProperties3._offValue;

      if (toggled === undefined) {
        var response = this.ddau('onToggle', {
          code: 'suggestion',
          oldValue: undefined,
          newValue: _offValue,
          context: this
        }, _offValue);
        // if container rejects suggestion disable control and throw error
        if (response === false) {
          this.set('disabled', true);
          this.ddau('onError', {
            code: 'invalid-value',
            value: undefined,
            validValues: [_onValue, _offValue],
            context: this
          }, null);
        }
      }
    },

    toggled: computed('value', 'onValue', 'offValue', function () {
      var _getProperties4 = this.getProperties('value', '_onValue', '_offValue');

      var value = _getProperties4.value;
      var _onValue = _getProperties4._onValue;
      var _offValue = _getProperties4._offValue;

      var validValues = a([_onValue, _offValue]);

      if (validValues.includes(value)) {
        return value === _onValue;
      } else {
        return undefined;
      }
    }),

    invalidState: computed('toggled', function () {
      return _ember['default'].typeOf(this.get('toggled')) === 'undefined' ? ' invalid-state' : '';
    }),

    _preferBoolean: function _preferBoolean(value) {
      if (value === 'true') {
        return true;
      }
      if (value === 'false') {
        return false;
      }

      return value;
    },

    _onValue: computed('_on', function () {
      var attrs = String(this.get('_on') || '').split('::');

      return this._preferBoolean(attrs.length === 1 ? attrs[0] : attrs[1]);
    }),

    _onLabel: computed('_on', function () {
      var _on = String(this.get('_on')) || '';
      return _on.split('::')[0];
    }),

    _offValue: computed('_off', function () {
      var attrs = String(this.get('_off') || '').split('::');

      return this._preferBoolean(attrs.length === 1 ? attrs[0] : attrs[1]);
    }),

    _offLabel: computed('_off', function () {
      var _off = String(this.get('_off')) || '';

      return _off.split('::')[0];
    }),

    themeClass: computed('theme', function () {
      var theme = this.get('theme') || 'default';

      return 'x-toggle-' + theme;
    }),

    forId: computed(function () {
      return this.get('elementId') + '-x-toggle';
    }),

    actions: {
      onClick: function onClick(e) {
        var _getProperties5 = this.getProperties('value', '_offValue', '_onValue');

        var value = _getProperties5.value;
        var _offValue = _getProperties5._offValue;
        var _onValue = _getProperties5._onValue;

        e.stopPropagation();
        e.preventDefault();
        var currentState = value === _onValue;
        var oldValue = currentState ? _onValue : _offValue;
        var newValue = currentState ? _offValue : _onValue;

        this.ddau('onToggle', {
          code: 'toggled',
          oldValue: oldValue,
          newValue: newValue,
          context: this
        }, newValue);
      },

      setToValue: function setToValue(state, e) {
        var _getProperties6 = this.getProperties('toggled', '_offValue', '_onValue');

        var toggled = _getProperties6.toggled;
        var _offValue = _getProperties6._offValue;
        var _onValue = _getProperties6._onValue;

        e.stopPropagation();
        e.preventDefault();

        if (toggled !== state) {
          this.ddau('onToggle', {
            code: 'set',
            oldValue: state ? _offValue : _onValue,
            newValue: !state ? _offValue : _onValue,
            context: this
          }, !state ? _offValue : _onValue);
        }
      }
    },

    /**
     * Provide a DDAU "action" or "mut" response
     * @param  {string } action The name of the exposed action property
     * @param  {hash}    hash   A hash of attributes that are passed back to a "action"
     * @param  {mixed}   value  A value that is passed to the "update" function (aka, mut helper) if available
     * @return {boolean}        Pass back true if `mut` not used; if used then proxies mut's response back
     */
    ddau: function ddau(action, hash, value) {
      if (this.attrs[action] && this.attrs[action].update) {
        this.attrs[action].update(value);
        return true;
      } else if (this.attrs[action]) {
        return this.attrs[action](hash);
      } else {
        // assume that container is using old-style actions
        this.sendAction(action, hash);
        return undefined;
      }
    }
  });

  xToggle[_ember['default'].NAME_KEY] = 'x-toggle';
  exports['default'] = xToggle;
});