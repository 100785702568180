define('ember-json-schema-views/components/schema-field-radio/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var schedule = Ember.run.schedule;
  var DEFAULT_TRUE_LABEL = exports.DEFAULT_TRUE_LABEL = 'True';
  var DEFAULT_FALSE_LABEL = exports.DEFAULT_FALSE_LABEL = 'False';

  exports.default = Component.extend({
    classNames: ['schema-field-component', 'schema-field-radio'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var key = this.get('key');
      var document = this.get('document');
      var defaultValue = this.get('property.default');

      schedule('afterRender', function () {
        var initialValue = null;
        var documentValue = document.get(key);

        if (typeof defaultValue !== 'undefined') {
          initialValue = defaultValue;
        }

        if (typeof documentValue !== 'undefined') {
          initialValue = documentValue;
        }

        if (initialValue !== null) {
          _this.set('value', initialValue);
          document.set(key, initialValue);
        }
      });
    },


    trueLabel: computed('property.displayProperties.labels.trueLabel', function () {
      return this.get('property.displayProperties.labels.trueLabel') || DEFAULT_TRUE_LABEL;
    }),

    falseLabel: computed('property.displayProperties.labels.falseLabel', function () {
      return this.get('property.displayProperties.labels.falseLabel') || DEFAULT_FALSE_LABEL;
    }),

    actions: {
      changed: function changed() {
        var document = this.get('document');
        var key = this.get('key');
        var value = this.$('input[type="radio"]:checked').val();

        if (value && value.toLowerCase() === 'true') {
          value = true;
        } else {
          value = false;
        }

        document.set(key, value);
        this.set('value', value);
        this.sendAction('changed', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
});