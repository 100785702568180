define('ember-json-schema-views/components/schema-field-text/component', ['exports', 'ember-json-schema-views/mixins/components/schema-field-initializer'], function (exports, _schemaFieldInitializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_schemaFieldInitializer.default, {
    classNames: ['schema-field-component', 'schema-field-text']
  });
});