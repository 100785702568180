define('ember-spread/mixins/spread', ['exports', 'ember', 'ember-prop-types'], function (exports, _ember, _emberPropTypes) {
  var Mixin = _ember['default'].Mixin;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;
  var get = _ember['default'].get;
  var isArray = _ember['default'].isArray;
  var isNone = _ember['default'].isNone;
  var makeArray = _ember['default'].makeArray;
  var typeOf = _ember['default'].typeOf;
  var assign = Object.assign;
  var keys = Object.keys;

  // Constants
  var SPREAD_PROPERTY = 'options';

  exports['default'] = Mixin.create({

    // == Dependencies ==========================================================

    // == Properties ============================================================

    propTypes: {
      // Keywords

      // Options
      options: _emberPropTypes.PropTypes.oneOfType([_emberPropTypes.PropTypes.EmberObject, _emberPropTypes.PropTypes.object]),
      spreadOptions: _emberPropTypes.PropTypes.shape({
        property: _emberPropTypes.PropTypes.string,
        source: _emberPropTypes.PropTypes.shape({
          object: _emberPropTypes.PropTypes.EmberObject.isRequired,
          property: _emberPropTypes.PropTypes.string.isRequired
        })
      })

      // State
    },

    // == Computed Properties ===================================================

    // == Functions =============================================================

    /**
     * Ember objects have a hook for dealing with previously undefined properties
     * which allows these properties to be brought into the observer system on-the-fly.
     *
     * Sets this object as a listener for any unknown property additions.
     *
     * @param {object} sourceObject - the source object for the spread
     * @param {string} sourceProperty - the source property for the spread
     * @param {string} spreadProperty - the locally bound property for the spread
     */
    _defineSourceListener: function _defineSourceListener(sourceObject, sourceProperty, spreadProperty) {
      // Get or create the array of spread listeners on the source object and add this object
      var spreadListeners = get(sourceObject, sourceProperty + '._spreadListeners');
      if (isNone(spreadListeners)) {
        get(sourceObject, sourceProperty).set('_spreadListeners', [{
          targetObject: this,
          targetProperty: spreadProperty
        }]);
      } else {
        spreadListeners.push({
          targetObject: this,
          targetProperty: spreadProperty
        });
      }

      // Define the setUnknownProperty function on the source property so that we can
      // monitor for the addition of new properties and spread them onto the local object
      defineProperty(get(sourceObject, sourceProperty), 'setUnknownProperty', undefined, function (key, value) {
        // Set the property to the given value (the expected normal behavior)
        this[key] = value;

        // For each listening target object (registered via spread options)
        // spread the new property onto the target object
        this._spreadListeners.forEach(function (listener) {
          if (typeOf(value) === 'function') {
            listener.targetObject.set(key, value);
          } else {
            defineProperty(listener.targetObject, key, computed.readOnly(listener.targetProperty + '.' + key));
          }
        });

        // Notify all downstream listeners that the property has changed.
        // This triggers the first observation of the property for the newly
        // defined computed property on the target object(s)
        sourceObject.get(sourceProperty).notifyPropertyChange(key);
      });
    },

    /**
     * Create local properties for each property in the spread hash.
     * Functions are set directly against the local object. Properties listed in
     * the component's `concatenatedProperties` or `mergedProperties` are
     * concatenated / merged appropriately.
     *
     * Note: These properties are not observed for changes.
     *
     * All other properties are readOnly computed properties to retain
     * observer behavior.
     *
     * Note: We're currently using the private Ember defineProperty function
     * which is required to establish observer chains (accept computed properties)
     *
     * @param {string} spreadProperty - the name of the local property containing the hash
     * @param {object} spreadableHash - the hash to spread
     * @param {string[]} staticProperties - properties that are not set up as an alias
     */
    _defineSpreadProperties: function _defineSpreadProperties(spreadProperty, spreadableHash) {
      var _this = this;

      var staticProperties = arguments.length <= 2 || arguments[2] === undefined ? ['tagName', 'elementId'] : arguments[2];

      assert(spreadProperty + ' requires an Ember object or primitive object', ['instance', 'object'].includes(typeOf(spreadableHash)));

      var concatenatedProperties = this.get('concatenatedProperties');
      var mergedProperties = this.get('mergedProperties');

      // NOTE: disabled linting rule to stay as close as possible to Ember core's code
      // eslint-disable-next-line complexity
      keys(spreadableHash).forEach(function (key) {
        var value = spreadableHash[key];

        if (staticProperties.includes(key) || typeOf(value) === 'function') {
          _this.set(key, value);
          return;
        }

        // Based on
        // https://github.com/emberjs/ember.js/blob/v2.12.0/packages/ember-runtime/lib/system/core_object.js#L127-L141
        if (Array.isArray(concatenatedProperties) && concatenatedProperties.indexOf(key) !== -1) {
          var baseValue = _this[key];

          if (!baseValue) {
            _this.set(key, makeArray(value));
          } else if (typeof baseValue.concat === 'function') {
            _this.set(key, baseValue.concat(value));
          } else {
            _this.set(key, makeArray(baseValue).concat(value));
          }

          return;
        }

        // Based on
        // https://github.com/emberjs/ember.js/blob/v2.12.0/packages/ember-runtime/lib/system/core_object.js#L143-L149
        if (Array.isArray(mergedProperties) && mergedProperties.indexOf(key) !== -1) {
          var originalValue = _this[key];

          if (typeOf(value) === 'object') {
            if (typeOf(originalValue) === 'object') {
              _this.set(key, assign({}, originalValue, value));
            } else {
              _this.set(key, assign({}, value));
            }
          }

          return;
        }

        defineProperty(_this, key, computed.readOnly(spreadProperty + '.' + key));
      });
    },

    /**
     * Get the source object and property for the spread hash
     *
     * @returns {object} - the source object and property for the spread hash
     */
    _getSourceContext: function _getSourceContext() {
      return {
        sourceObject: this.get('spreadOptions.source.object'),
        sourceProperty: this.get('spreadOptions.source.property')
      };
    },

    /**
     * @param {object} listener - a listener object for setUnknownProperty
     * @returns {boolean} - true if the given listener came from this object
     */
    _isLocalListener: function _isLocalListener(listener) {
      return listener.targetObject === this;
    },

    // == Ember Lifecycle Hooks =================================================

    init: function init() {
      this._super.apply(this, arguments);

      // Get the spreadable hash
      var spreadProperty = this.get('spreadOptions.property') || SPREAD_PROPERTY;
      var spreadableHash = this.get(spreadProperty);
      if (isNone(spreadableHash)) {
        return;
      }

      // Spread the properties in the hash onto the local object
      this._defineSpreadProperties(spreadProperty, spreadableHash);

      // The above spread only works on properties that were defined on the
      // hash when it was passed to this context.  However, if we add a listener
      // to the original object hash in the original context then we can determine
      // when a new property is added and define a property in this context on-the-fly

      var _getSourceContext2 = this._getSourceContext();

      var sourceObject = _getSourceContext2.sourceObject;
      var sourceProperty = _getSourceContext2.sourceProperty;

      if (isNone(sourceObject) || isNone(sourceProperty)) {
        return;
      }

      // Define a listener for any new properties on the source property
      this._defineSourceListener(sourceObject, sourceProperty, spreadProperty);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var _getSourceContext3 = this._getSourceContext();

      var sourceObject = _getSourceContext3.sourceObject;
      var sourceProperty = _getSourceContext3.sourceProperty;

      if (isNone(sourceObject) || isNone(sourceProperty)) {
        return;
      }

      var spreadListeners = get(sourceObject, sourceProperty + '._spreadListeners');

      // Remove this listener from the source object property
      if (isArray(spreadListeners)) {
        spreadListeners.splice(spreadListeners.findIndex(this._isLocalListener), 1);
      }
    }

    // == DOM Events ============================================================

    // == Actions ===============================================================

  });
});
/**
 * ember-spread mixin
 *
 * Spreads the properties from a source object against the root level of the local object
 */