define('ember-json-schema-views/components/each-property/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getPropertyInputType = getPropertyInputType;
  var get = Ember.get;
  var Component = Ember.Component;
  var computed = Ember.computed;
  function getPropertyInputType(property) {
    if (property.type === 'array') {
      return 'checkbox';
    }

    if (property.type === 'object') {
      return 'object';
    }

    if (property.validValues && Array.isArray(property.validValues)) {
      return 'select';
    }

    if (property.type === 'boolean') {
      if (get(property, 'displayProperties.useToggle')) {
        return 'toggle';
      } else {
        return 'radio';
      }
    }

    return 'text';
  }

  exports.default = Component.extend({
    tagName: '',
    recursive: true,
    propertyCollection: computed('properties.[]', function () {
      var _getProperties = this.getProperties('properties', 'recursive'),
          properties = _getProperties.properties,
          recursive = _getProperties.recursive;

      var propertyKeys = Object.keys(properties);

      return propertyKeys.map(function (key) {
        var property = properties[key];
        var showChildProperties = recursive && property.properties;

        return { key: key, property: property, type: getPropertyInputType(property),
          showChildProperties: showChildProperties, childProperties: property.properties };
      });
    })
  });
});