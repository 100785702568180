define('ember-validations/errors', ['exports', 'ember'], function (exports, _ember) {
  var emberArray = _ember['default'].A;
  var EmberObject = _ember['default'].Object;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  exports['default'] = EmberObject.extend({
    unknownProperty: function unknownProperty(property) {
      var _this = this;

      var val = emberArray();
      run.once(function () {
        return set(_this, property, val);
      });
      return val;
    }
  });
});