define('ember-json-schema-document/models/document-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Object.extend({
    serialize: function serialize() {
      return this.serializeHash(this);
    },
    serializeHash: function serializeHash(hash) {
      var newAttrs = {};
      var excludeMatch = /^_/ig;

      for (var key in hash) {
        if (hash.hasOwnProperty(key) && !excludeMatch.test(key)) {
          var val = hash[key];

          if (typeof val === 'undefined' || val === null) {
            continue;
          }

          if (Array.isArray(val)) {
            val = val;
          } else if ((typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object') {
            val = this.serializeHash(val);
          } else if (typeof val.serialize === 'function') {
            val = val.serialize();
          }

          newAttrs[key] = val;
        }
      }
      return newAttrs;
    }
  });
});