define('ember-json-schema-views/mixins/components/schema-field-initializer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var schedule = Ember.run.schedule;
  var computed = Ember.computed;
  exports.default = Mixin.create({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var key = this.get('key');
      var jsonDocument = this.get('document');
      var defaultValue = this.get('property.default');

      schedule('afterRender', function () {
        var initialValue = jsonDocument.get(key) || defaultValue || '';
        _this.set('value', initialValue);
        jsonDocument.set(key, initialValue);
      });
    },
    getCurrentValue: function getCurrentValue() {
      this.$('input').val();
    },


    disabled: computed('property.readonly', function () {
      if (this.get('property.readonly')) {
        return 'disabled';
      }

      return false;
    }),

    actions: {
      update: function update(value) {
        if (typeof value === 'undefined') {
          value = this.getCurrentValue();
        }
        var jsonDocument = this.get('document');
        var key = this.get('key');

        jsonDocument.set(key, value);
        this.set('value', value);
        this.sendAction('changed', value); // eslint-disable-line ember/closure-actions
      }
    }
  });
});