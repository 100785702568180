define('ember-json-schema-document/models/schema', ['exports', 'ember-json-schema-document/utils/get-properties', 'ember-json-schema-document/models/document'], function (exports, _getProperties, _document) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Schema = function () {
    function Schema(schema) {
      _classCallCheck(this, Schema);

      if (!schema) {
        throw new Error('You must provide a schema object to the Schema constructor.');
      }

      this._setupSchema(schema);
    }

    _createClass(Schema, [{
      key: 'buildDocument',
      value: function buildDocument(data) {
        return _document.default.build(this, this._schema.type, data);
      }
    }, {
      key: '_setupSchema',
      value: function _setupSchema(schema) {
        this._properties = null;
        this._schema = schema;
        this._schemaStack = [this];
      }
    }, {
      key: 'resolveURI',
      value: function resolveURI(uri) {
        var parts = uri.split('/');
        if (parts[0] === '' || parts[0] === '#') {
          parts.shift();
        }

        var property = this._schema;

        do {
          var part = parts.shift();
          property = property[part];
        } while (parts.length > 0);

        return property;
      }
    }, {
      key: 'schema',
      get: function get() {
        return this._schema;
      },
      set: function set(newSchema) {
        this._setupSchema(newSchema);
      }
    }, {
      key: 'id',
      get: function get() {
        return this._schema.id;
      }
    }, {
      key: 'required',
      get: function get() {
        return this._schema.required || [];
      }
    }, {
      key: 'properties',
      get: function get() {
        // TODO: throw an error if called on an array base type
        return (0, _getProperties.default)(this, this._schema.properties);
      }
    }, {
      key: 'itemProperties',
      get: function get() {
        // TODO: throw an error if called on an object base type
        return (0, _getProperties.default)(this, this._schema.items.properties);
      }
    }, {
      key: 'schemaStack',
      get: function get() {
        return this._schemaStack;
      }
    }], [{
      key: 'load',
      value: function load(schemaUrl, options) {
        var ajax = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Ember.$.ajax;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          options.dataType = 'json';
          options.contentType = 'application/schema+json';

          ajax(schemaUrl, options).then(Ember.run.bind(null, function (payload) {
            if (payload) {
              resolve(new Schema(payload));
            } else {
              reject('Unable to load schema located at ' + schemaUrl);
            }
          }), Ember.run.bind(null, reject));
        });
      }
    }]);

    return Schema;
  }();

  exports.default = Schema;
});